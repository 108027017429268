var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "confirm-and-pay-form__container" }, [
    _c("div", { staticClass: "inner-white-sheet__container" }, [
      _c("div", { staticClass: "grid-container inner-form" }, [
        _c(
          "div",
          {
            staticClass:
              "confirm-and-pay-form__row grid-item-delivery-detail inner-grid-list-container"
          },
          [
            _c("div", { staticClass: "confirm-and-pay-form__row-label" }, [
              _vm._v(
                "\n          " + _vm._s(_vm.text.deliveryDetail) + "\n        "
              )
            ]),
            _c(
              "div",
              {
                staticClass: "confirm-and-pay-form__field inner-grid-container"
              },
              [
                _c("div", { staticClass: "label__field left-item" }, [
                  _vm._v(_vm._s(_vm.text.shipmentType))
                ]),
                _c("div", { staticClass: "data__field right-item" }, [
                  _vm._v(_vm._s(_vm.shipmentType))
                ])
              ]
            ),
            _vm.isSubprime
              ? _c(
                  "div",
                  {
                    staticClass:
                      "confirm-and-pay-form__field inner-grid-container"
                  },
                  [
                    _c("div", { staticClass: "label__field left-item" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t(
                              "shipment.confirmAndPayForm.confirmAndPayForm.mainTrackingNumber"
                            )
                          ) +
                          "\n          "
                      )
                    ]),
                    _c("div", { staticClass: "data__field right-item" }, [
                      _vm._v(_vm._s(_vm.expressOrderItems[0].trackingNumber))
                    ])
                  ]
                )
              : _vm._e(),
            _vm._l(_vm.expressOrderItems.slice(1), function(expressOrderItem) {
              return _c(
                "div",
                {
                  key: expressOrderItem.trackingNumber,
                  staticClass: "sub_tracking_numbers"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "confirm-and-pay-form__field inner-grid-container"
                    },
                    [
                      _c("div", { staticClass: "label__field left-item" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.$t(
                                "shipment.confirmAndPayForm.confirmAndPayForm.itemTrackingNumber"
                              )
                            ) +
                            "\n            "
                        )
                      ]),
                      _c("div", { staticClass: "data__field right-item" }, [
                        _vm._v(_vm._s(expressOrderItem.trackingNumber))
                      ])
                    ]
                  )
                ]
              )
            }),
            _vm.isSubprime
              ? _c(
                  "div",
                  {
                    staticClass:
                      "confirm-and-pay-form__field inner-grid-container"
                  },
                  [
                    _c("div", { staticClass: "label__field left-item" }, [
                      _vm._v(_vm._s(_vm.text.isDocument))
                    ]),
                    _c("div", { staticClass: "data__field right-item" }, [
                      _vm._v(_vm._s(_vm.isDocumentText))
                    ])
                  ]
                )
              : _vm._e(),
            _vm.isDocument
              ? _c(
                  "div",
                  {
                    staticClass:
                      "confirm-and-pay-form__field inner-grid-container"
                  },
                  [
                    _c("div", { staticClass: "label__field left-item" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t(
                              "shipment.confirmAndPayForm.confirmAndPayForm.deliveryDate"
                            )
                          ) +
                          "\n          "
                      )
                    ]),
                    _c(
                      "div",
                      { staticClass: "data__field right-item address-data" },
                      [_c("p", [_vm._v(_vm._s(_vm.deliveryDate))])]
                    )
                  ]
                )
              : _vm._e(),
            _vm.isDocument
              ? _c(
                  "div",
                  {
                    staticClass:
                      "confirm-and-pay-form__field inner-grid-container"
                  },
                  [
                    _c("div", { staticClass: "label__field left-item" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t(
                              "shipment.confirmAndPayForm.confirmAndPayForm.pickUpDate"
                            )
                          ) +
                          "\n          "
                      )
                    ]),
                    _c(
                      "div",
                      { staticClass: "data__field right-item address-data" },
                      [_c("p", [_vm._v(_vm._s(_vm.pickUpDate))])]
                    )
                  ]
                )
              : _vm._e(),
            _c("div", { staticClass: "top-border" }),
            _c(
              "div",
              {
                staticClass: "confirm-and-pay-form__field inner-grid-container"
              },
              [
                _c("div", { staticClass: "label__field left-item" }, [
                  _vm._v(_vm._s(_vm.text.from))
                ]),
                _c("div", { staticClass: "data__field right-item" }, [
                  _vm._v(_vm._s(_vm.from.contactName))
                ])
              ]
            ),
            _c(
              "div",
              {
                staticClass: "confirm-and-pay-form__field inner-grid-container"
              },
              [
                _c("div", { staticClass: "label__field left-item" }, [
                  _vm._v(_vm._s(_vm.text.contact))
                ]),
                _c("div", { staticClass: "data__field right-item" }, [
                  _vm._v(_vm._s(_vm.from.contactPhone))
                ])
              ]
            ),
            _c(
              "div",
              {
                staticClass: "confirm-and-pay-form__field inner-grid-container"
              },
              [
                _c("div", { staticClass: "label__field left-item" }, [
                  _vm._v(_vm._s(_vm.text.address) + ":")
                ]),
                _c(
                  "div",
                  { staticClass: "data-field right-item address-data" },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.fromAddressString) +
                        "\n          "
                    )
                  ]
                )
              ]
            ),
            _c("div", { staticClass: "top-border" }),
            _c(
              "div",
              {
                staticClass: "confirm-and-pay-form__field inner-grid-container"
              },
              [
                _c("div", { staticClass: "label__field left-item" }, [
                  _vm._v(_vm._s(_vm.text.destination))
                ]),
                _c("div", { staticClass: "data__field right-item" }, [
                  _vm._v(_vm._s(_vm.to.contactName))
                ])
              ]
            ),
            _c(
              "div",
              {
                staticClass: "confirm-and-pay-form__field inner-grid-container"
              },
              [
                _c("div", { staticClass: "label__field left-item" }, [
                  _vm._v(_vm._s(_vm.text.contact))
                ]),
                _c("div", { staticClass: "data__field right-item" }, [
                  _vm._v(_vm._s(_vm.to.contactPhone))
                ])
              ]
            ),
            _c(
              "div",
              {
                staticClass: "confirm-and-pay-form__field inner-grid-container"
              },
              [
                _c("div", { staticClass: "label__field left-item" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        (_vm.pickUpStoreId
                          ? _vm.$t("pickUpStore.pickUpStore")
                          : "") + _vm.text.address
                      ) +
                      ":\n          "
                  )
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "data-field right-item address-data pre-wrap-text"
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.toAddressString) +
                        "\n          "
                    )
                  ]
                )
              ]
            )
          ],
          2
        ),
        _c(
          "div",
          {
            staticClass:
              "confirm-and-pay-form__row grid-item-account inner-grid-list-container"
          },
          [
            _c("div", { staticClass: "confirm-and-pay-form__row-label" }, [
              _vm._v(_vm._s(_vm.text.account))
            ]),
            _c(
              "div",
              {
                staticClass: "confirm-and-pay-form__field inner-grid-container"
              },
              [
                _c("div", { staticClass: "label__field left-item" }, [
                  _vm._v(_vm._s(_vm.text.balance))
                ]),
                _c("div", { staticClass: "data__field right-item" }, [
                  _vm._v(_vm._s(_vm.accountBalance))
                ])
              ]
            )
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "confirm-and-pay-form__row grid-item-items-detail inner-grid-list-container"
          },
          [
            _c("div", { staticClass: "confirm-and-pay-form__row-label" }, [
              _vm._v(
                "\n          " + _vm._s(_vm.text.shipmentDetail) + "\n        "
              )
            ]),
            !_vm.isSubprime
              ? _c(
                  "div",
                  { staticClass: "confirm-and-pay-form__field" },
                  _vm._l(_vm.items, function(item) {
                    return _c(
                      "div",
                      {
                        key: item.itemId,
                        staticClass: "inner-grid-item-container"
                      },
                      [
                        _c("div", { staticClass: "label__field left-item" }, [
                          _vm._v(_vm._s(item.description))
                        ]),
                        _c(
                          "div",
                          {
                            staticClass:
                              "item-value__field data__field right-item"
                          },
                          [_vm._v(" x " + _vm._s(item.quantity) + " ")]
                        )
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm._l(_vm.expressOrderItems, function(expressOrderItem, index) {
              return _c(
                "div",
                {
                  key: expressOrderItem.trackingNumber,
                  staticClass: "confirm-and-pay-form__field"
                },
                [
                  _c(
                    "div",
                    { staticClass: "inner-grid-express-order-item-container" },
                    [
                      _c("div", { staticClass: "label__field left-item" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.$t(
                                "shipment.confirmAndPayForm.confirmAndPayForm.trackingNumber"
                              ) +
                                (index + 1)
                            ) +
                            "\n            "
                        )
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "item-value__field data__field right-item"
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(expressOrderItem.trackingNumber) +
                              "\n            "
                          )
                        ]
                      )
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "inner-grid-express-order-item-container" },
                    [
                      _c("div", { staticClass: "label__field left-item" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.$t(
                                "shipment.confirmAndPayForm.confirmAndPayForm.itemCategory"
                              )
                            ) +
                            "\n            "
                        )
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "item-value__field data__field right-item"
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                (_vm.ROOT.$i18n.locale === "cant"
                                  ? expressOrderItem.categoryDescriptionChi
                                  : expressOrderItem.categoryDescriptionEn) ||
                                  expressOrderItem.category
                              ) +
                              "\n            "
                          )
                        ]
                      )
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "inner-grid-express-order-item-container" },
                    [
                      _c("div", { staticClass: "label__field left-item" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.$t(
                                "shipment.shipmentForm.itemForm.dimensionOption"
                              )
                            ) +
                            "\n            "
                        )
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "item-value__field data__field right-item"
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.ROOT.$i18n.locale === "cant"
                                  ? expressOrderItem.dimensionDescriptionChi
                                  : expressOrderItem.dimensionDescriptionEn
                              ) +
                              "\n            "
                          )
                        ]
                      )
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "inner-grid-express-order-item-container" },
                    [
                      _c("div", { staticClass: "label__field left-item" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.isDocument
                                ? _vm.$t(
                                    "shipment.shipmentForm.itemForm.weightOption"
                                  )
                                : _vm.$t(
                                    "shipment.shipmentForm.itemForm.actualWeight"
                                  )
                            ) +
                            "\n            "
                        )
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "item-value__field data__field right-item"
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.isDocument
                                  ? _vm.ROOT.$i18n.locale === "cant"
                                    ? expressOrderItem.weightDescriptionChi
                                    : expressOrderItem.weightDescriptionEn
                                  : [
                                      expressOrderItem.actualWeight,
                                      _vm.$t(
                                        "shipment.shipmentForm.itemForm.kg"
                                      )
                                    ].join("")
                              ) +
                              "\n            "
                          )
                        ]
                      )
                    ]
                  ),
                  _vm.isDocument
                    ? _c(
                        "div",
                        {
                          staticClass: "inner-grid-express-order-item-container"
                        },
                        [
                          _c("div", { staticClass: "label__field left-item" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t(
                                    "shipment.shipmentForm.itemForm.temperatureOption"
                                  )
                                ) +
                                "\n            "
                            )
                          ]),
                          _c(
                            "div",
                            {
                              staticClass:
                                "item-value__field data__field right-item"
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.ROOT.$i18n.locale === "cant"
                                      ? expressOrderItem.temperatureDescriptionChi
                                      : expressOrderItem.temperatureDescriptionEn
                                  ) +
                                  "\n            "
                              )
                            ]
                          )
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass:
                        "confirm-and-pay-form__field inner-grid-container"
                    },
                    [
                      _c("div", { staticClass: "label__field left-item" }, [
                        _vm._v(_vm._s(_vm.text.orderNotes))
                      ]),
                      _c("div", { staticClass: "data__field right-item" }, [
                        _vm._v(_vm._s(expressOrderItem.orderNotes))
                      ])
                    ]
                  )
                ]
              )
            }),
            _c("div", { staticClass: "top-border" }),
            _c(
              "div",
              {
                staticClass:
                  "confirm-and-pay-form__field inner-grid-express-order-item-container"
              },
              [
                _c("div", { staticClass: "label__field left-item" }, [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        height: "100%",
                        display: "flex",
                        "align-items": "flex-start"
                      }
                    },
                    [_vm._v(_vm._s(_vm.text.totalCharge))]
                  ),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "vertical-align": "middle",
                        display: "inline-block",
                        height: "min-content",
                        width: "min-content"
                      }
                    },
                    [
                      _vm.isSubprime
                        ? _c("TheMDCIconButton", {
                            attrs: {
                              reference: "cost-warning",
                              icon: "info",
                              event: "show-prompt",
                              color: "red",
                              removeVerticalPadding: "true"
                            },
                            on: { "show-prompt": _vm.showCostWaringPrompt }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "data__field right-item" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.text.currency) +
                      " " +
                      _vm._s(_vm.totalCharge) +
                      " "
                  )
                ])
              ]
            ),
            _vm.isSubprime
              ? _c(
                  "div",
                  {
                    staticClass:
                      "confirm-and-pay-form__field inner-grid-container"
                  },
                  [
                    _c("div", { staticClass: "label__field left-item" }, [
                      _vm._v(_vm._s(_vm.text.paymentMethod))
                    ]),
                    _c("div", { staticClass: "data__field right-item" }, [
                      _vm._v(_vm._s(_vm.paymentMethodText))
                    ])
                  ]
                )
              : _vm._e(),
            _vm.isSubprime &&
            (_vm.paymentMethod === "CASH_ON_DELIVERY" ||
              _vm.paymentMethod === "PAID_BY_MERCHANT_COD")
              ? _c(
                  "div",
                  {
                    staticClass:
                      "confirm-and-pay-form__field inner-grid-container"
                  },
                  [
                    _c("div", { staticClass: "label__field left-item" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.text.cashOnDeliveryAmount) +
                          "\n          "
                      )
                    ]),
                    _c("div", { staticClass: "data__field right-item" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.text.currency) +
                          " " +
                          _vm._s(_vm.cashOnDeliveryAmount) +
                          " "
                      )
                    ])
                  ]
                )
              : _vm._e(),
            _vm.isSubprime
              ? _c("div", { staticClass: "top-border" })
              : _vm._e(),
            _vm.isSubprime
              ? _c(
                  "div",
                  {
                    staticClass:
                      "confirm-and-pay-form__field inner-grid-container"
                  },
                  [
                    _c("div", { staticClass: "label__field left-item" }, [
                      _vm._v(_vm._s(_vm.text.recipientCharge))
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "data__field right-item",
                        staticStyle: { "font-weight": "bold" }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.text.currency) +
                            " " +
                            _vm._s(_vm.recipientCharge) +
                            "\n          "
                        )
                      ]
                    )
                  ]
                )
              : _vm._e(),
            _vm.isSubprime
              ? _c(
                  "div",
                  {
                    staticClass:
                      "confirm-and-pay-form__field inner-grid-container"
                  },
                  [
                    _c("div", { staticClass: "label__field left-item" }, [
                      _vm._v(_vm._s(_vm.text.senderCharge))
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "data__field right-item",
                        staticStyle: { "font-weight": "bold" }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.text.currency) +
                            " " +
                            _vm._s(_vm.senderCharge) +
                            " "
                        )
                      ]
                    )
                  ]
                )
              : _vm._e(),
            _c("div", { staticClass: "top-border" })
          ],
          2
        ),
        _c(
          "div",
          {
            staticClass:
              "confirm-and-pay-form__row grid-item-buttons inner-grid-button-container"
          },
          [
            _c(
              "div",
              { staticClass: "confirm-and-pay-form__field" },
              [
                _c("TheMDCButtonShaped", {
                  ref: "return",
                  attrs: {
                    label: _vm.text.return,
                    reference: "return",
                    event: "return"
                  },
                  on: {
                    return: function($event) {
                      return _vm.toLastPage()
                    }
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "confirm-and-pay-form__field" },
              [
                _c("TheMDCButtonShapedRaised", {
                  ref: "proceed",
                  attrs: {
                    label: _vm.text.proceed,
                    reference: "proceed",
                    event: "proceed",
                    startIcon: "attach_money"
                  },
                  on: {
                    proceed: function($event) {
                      return _vm.isProceedPrompt()
                    }
                  }
                })
              ],
              1
            )
          ]
        ),
        _c(
          "div",
          { staticClass: "confirm-and-pay-form__row grid-item-footer" },
          [
            _c(
              "div",
              {
                staticClass: "footer",
                staticStyle: { color: "lightgrey", "text-align": "right" }
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.thisDate.toLocaleDateString()) +
                    "\n          " +
                    _vm._s(_vm.thisDate.toLocaleTimeString()) +
                    "\n        "
                )
              ]
            )
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }