import i18n from "@/i18n";
import store from "@/store";
import router from "@/router";
import { Alpha2ToName, itemCategories } from "@/assets/constant";
import { fetchShipmentRecordbyId } from "@/utils/fetchDataHelpers";
import { SessionStorageHandler as Storage } from "@/utils/sessionStorageHandler";
// https://github.com/alsotang/is-chinese/blob/master/lib/is_chinese.js
const chineseRange = [
    // // Chinese punctuation
    // 0x00b7, //·
    // 0x00d7, //×
    // 0x2014, //—
    // 0x2018, //‘
    // 0x2019, //’
    // 0x201c, //"
    // 0x201d, //"
    // 0x2026, //…
    // 0x3001, //、
    // 0x3002, //。
    // 0x300a, //《
    // 0x300b, //》
    // 0x300e, //『
    // 0x300f, //』
    // 0x3010, //【
    // 0x3011, //】
    // 0xff01, //！
    // 0xff08, //（
    // 0xff09, //）
    // 0xff0c, //，
    // 0xff1a, //：
    // 0xff1b, //；
    // 0xff1f, //？
    [0x4e00, 0x9fff], // CJK Unified Ideographs
    [0x3400, 0x4dbf], // CJK Unified Ideographs Extension A
    [0x20000, 0x2a6df], // CJK Unified Ideographs Extension B
    [0x2a700, 0x2b73f], // CJK Unified Ideographs Extension C
    [0x2b740, 0x2b81f], // CJK Unified Ideographs Extension D
    [0x2b820, 0x2ceaf], // CJK Unified Ideographs Extension E
    [0xf900, 0xfaff], // CJK Compatibility Ideographs
    [0x3300, 0x33ff], // https://en.wikipedia.org/wiki/CJK_Compatibility
    [0xfe30, 0xfe4f], // https://en.wikipedia.org/wiki/CJK_Compatibility_Forms
    [0xf900, 0xfaff], // https://en.wikipedia.org/wiki/CJK_Compatibility_Ideographs
    [0x2f800, 0x2fa1f], // https://en.wikipedia.org/wiki/CJK_Compatibility_Ideographs_Supplement
  ]
  // cache the generated RegExp
  let REGEX_CHINESE: RegExp | undefined = undefined
  export function getChineseRegExp(): RegExp {
    if (!REGEX_CHINESE) {
      REGEX_CHINESE = new RegExp(
        chineseRange.map(([a, b]) => `[\\u{${a.toString(16)}}-\\u{${b.toString(16)}}]`).join('|'),
        'u',
      )
    }
    return REGEX_CHINESE
  }
  // const REGEX_CHINESE = new RegExp(
  //   chineseRange.map(([a, b]) => `[\\u{${a.toString(16)}}-\\u{${b.toString(16)}}]`).join('|'),
  //   'u',
  // )
  /**
   * Test whether the given string includes any chinese character
   * @param  {string} str string to test
   * @returns boolean
   *
   * For example:
   *
   * hasChinese('你好ma？?') => true,
   *
   * hasChinese('？?') => false
   */
  export function hasChinese(str: string): boolean {
    return getChineseRegExp().test(str + '')
  }
  /**
   * Extract chinese characters from a given string
   * @param  {string} str string to extract from
   * @returns string string with only chinese characters
   *
   * For example:
   *
   * extractChinese('你好ma？?') => '你好'
   */
  export function extractChinese(str: string): string {
    return (str + '')
      .split('')
      .filter(hasChinese)
      .join('')
  }


export function paymentMethodText(paymentMethod: string) {
    switch (paymentMethod){
        case 'PAID_BY_MERCHANT':
        return  i18n.t("shipment.paymentMethod.paidByMerchant");
        case 'PAID_BY_RECEIVER':
            return  i18n.t("shipment.paymentMethod.paidByRecipient");
        case 'CASH_ON_DELIVERY':
            return  i18n.t("shipment.paymentMethod.cashOnDelivery");
        case 'PAID_BY_MERCHANT_COD':
          return  i18n.t("shipment.paymentMethod.paidByMerchantCashOnDelivery");
        default:

        return "-";
    }    
}

export function weekDayTranslate(dateString: string): string{
  if(isNaN(Date.parse(dateString))){
    return dateString;
  }

  switch(new Date(dateString).getDay()){
    case 0:
      return i18n.t("constants.dayOfWeek.sunday") as string;
    case 1:
      return i18n.t("constants.dayOfWeek.monday") as string;
    case 2:
      return i18n.t("constants.dayOfWeek.tuesday") as string;
    case 3:
      return i18n.t("constants.dayOfWeek.wednesday") as string;
    case 4:
      return i18n.t("constants.dayOfWeek.thursday") as string;
    case 5:
      return i18n.t("constants.dayOfWeek.friday") as string;
    case 6:
      return i18n.t("constants.dayOfWeek.saturday") as string;
    default:
      return dateString;
  }
}


