










































































































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import TheMDCButtonShaped from '@/components/mdcComponents/buttons/TheMDCButtonShaped.vue';
import TheMDCButtonShapedRaised from '@/components/mdcComponents/buttons/TheMDCButtonShapedRaised.vue';
import TheMDCIconButton from '@/components/mdcComponents/buttons/TheMDCIconButton.vue';

import * as serverResponseHelpers from '@/utils/serverResponseHelpers';
import * as TranslationHelpers from '@/utils/translationHelpers';
import * as helper from '@/utils/fetchDataHelpers.ts';
import { ShipmentHelpers } from '@/utils/shipmentHelpers.ts';

import App from '@/App.vue';

@Component({
  components: {
    TheMDCButtonShaped,
    TheMDCButtonShapedRaised,
    TheMDCIconButton,
  },
})
export default class ConfirmAndPayForm extends Vue implements VuePromptCaller {
  // Visiter Pattern
  public on(event: 'accept' | 'close', payload?: any, execution?: CallBack<void> | null) {
    if (event === 'accept') {
      if (execution) {
        execution(event, payload);
      }
    }
  }
  private reset = false;
  private ROOT = this.$root.$children[0] as App;

  private DOCUMENT_COST = Number(process.env.VUE_APP_SAME_DAY_DELIVERY_PRICE); // document changed to same day delivery
  private NON_DOCUMENT_COST = Number(process.env.VUE_APP_NON_SAME_DAY_DELIVERY_PRICE); // non document = normal stuff
  get text() {
    return {
      // title: "Confirmation",
      shipmentDetail: this.$t('shipment.confirmAndPayForm.confirmAndPayForm.shipmentDetail'),
      deliveryDetail: this.$t('shipment.confirmAndPayForm.confirmAndPayForm.deliveryDetail'),
      account: this.$t('shipment.confirmAndPayForm.confirmAndPayForm.account'),

      // label
      date: this.$t('shipment.confirmAndPayForm.confirmAndPayForm.date'),
      from: this.$t('shipment.confirmAndPayForm.confirmAndPayForm.from'),
      destination: this.$t('shipment.confirmAndPayForm.confirmAndPayForm.destination'),
      contact: this.$t('shipment.confirmAndPayForm.confirmAndPayForm.contact'),
      address: this.$t('shipment.confirmAndPayForm.confirmAndPayForm.address'),
      balance: this.$t('shipment.confirmAndPayForm.confirmAndPayForm.balance'),

      isInsured: this.$t('shipment.confirmAndPayForm.confirmAndPayForm.isInsured'),
      taxesDutiesPaidBy: this.$t('shipment.confirmAndPayForm.confirmAndPayForm.taxesDutiesPaidBy'),
      totalCharge: this.$t('shipment.confirmAndPayForm.confirmAndPayForm.totalCharge'),
      courier: this.$t('shipment.confirmAndPayForm.confirmAndPayForm.courier'),

      pickupTime: this.$t('shipment.confirmAndPayForm.confirmAndPayForm.pickupTime'),
      shipmentType: this.$t('shipment.confirmAndPayForm.confirmAndPayForm.shipmentType'),
      isDocument: this.$t('shipment.confirmAndPayForm.confirmAndPayForm.isDocument'),
      paymentMethod: this.$t('shipment.paymentMethod.paymentMethod'),
      cashOnDeliveryAmount: this.$t('shipment.paymentMethod.cashOnDeliveryAmount'),
      orderNotes: this.$t('shipment.confirmAndPayForm.confirmAndPayForm.orderNotes'),
      senderCharge: this.$t('shipment.confirmAndPayForm.confirmAndPayForm.senderCharge'),
      recipientCharge: this.$t('shipment.confirmAndPayForm.confirmAndPayForm.recipientCharge'),

      // text
      currency: this.$t('shipment.confirmAndPayForm.confirmAndPayForm.currency'),
      pickup: this.$t('shipment.confirmAndPayForm.confirmAndPayForm.pickup'),
      markAsDirectlyHandover: this.$t('shipment.confirmAndPayForm.confirmAndPayForm.markAsDirectlyHandover'),
      to: this.$t('shipment.confirmAndPayForm.confirmAndPayForm.to'),

      paidByMerchant: this.$t('shipment.paymentMethod.paidByMerchant'),
      paidByRecipient: this.$t('shipment.paymentMethod.paidByRecipient'),
      cashOnDelivery: this.$t('shipment.paymentMethod.cashOnDelivery'),
      costWarningMsg: this.$t('shipment.confirmAndPayForm.confirmAndPayForm.costWarningPrompt.message'),
      userLowCreditBalance: this.$t('shipment.confirmAndPayForm.confirmAndPayForm.userLowCreditBalance'),

      // buttons
      return: this.$t('shipment.confirmAndPayForm.confirmAndPayForm.return'),
      proceed: this.$t('shipment.confirmAndPayForm.confirmAndPayForm.proceed'),
      confirmationMsg: this.$t('shipment.confirmAndPayForm.confirmAndPayForm.confirmationMsg'),

      // error
      userInsufficientBalance: this.$t('shipment.confirmAndPayForm.confirmAndPayForm.userInsufficientBalance'),
      userInputError: this.$t('shipment.confirmAndPayForm.confirmAndPayForm.userInputError'),

      // success
      successTitle: this.$t('shipment.confirmAndPayForm.confirmAndPayForm.successTitle'),
      successMessage: this.$t('shipment.confirmAndPayForm.confirmAndPayForm.successMessage'),
    };
  }

  mounted() {
    this.$store.commit('showTitle');
    this.$store.commit('hideNavBar');
    this.$store.commit('showTopBar');

    this.$emit('confirm'); // change progress bar
    this.$nextTick(function() {
      // Code that will run only after the
      // entire view has been rendered
      this.$store.commit('hideLoading');
    });
  }

  get isSubprime() {
    return this.$store.getters['shipment/getShipmentIsSubprime'];
  }
  get isDocument() {
    return this.$store.getters['shipment/getShipmentIsDocument'];
  }

  get pickUpStoreId() {
    return this.$store.getters['shipment/getPickUpStoreId'];
  }
  get pickUpStore() {
    return this.$store.getters['pickUpStore/getPickUpStoreById'](this.pickUpStoreId);
  }
  get accountBalance() {
    return this.$store.getters.getCompany?.accountBalance || 0;
  }

  get thisDate() {
    const now = new Date();
    return now;
  }

  get from(): Address | ExpressOrderAddress {
    return this.$store.getters['shipment/getShipmentSenderAddress'];
  }

  get to(): any {
    if (!this.pickUpStoreId) {
      return this.$store.getters['shipment/getShipmentReceiverAddress'];
    } else {
      return {
        contactName: this.$store.getters['shipment/getPickUpName'],
        contactPhone: this.$store.getters['shipment/getPickUpPhone'],
      };
    }
  }

  get items(): Item[] {
    return this.$store.getters['shipment/getShipmentItems'];
  }

  get expressOrderItems(): ExpressOrderItem[] {
    const orders: ExpressOrderItem[] = this.$store.getters['shipment/getExpressOrderItems'];
    return orders.map((order: ExpressOrderItem) => ShipmentHelpers.updateExpressOrderItemDescription(order));
  }

  get isInsured() {
    return this.$store.getters['shipment/getShipmentIsInsured'] ? 'Insured' : 'Not Insured';
  }

  get taxesDutiesPaidBy() {
    return this.$store.getters['shipment/getShipmentTaxesDutiesPaidBy'];
  }

  get totalCharge() {
    if (this.isSubprime) {
      return ShipmentHelpers.calculateShipmentPrice(this.expressOrderItems, this.isDocument);
    }
    return this.$store.getters['shipment/getSelectedRate'].totalCharge;
  }

  get senderCharge() {
    if (this.isSubprime) {
      if (this.paymentMethod === 'PAID_BY_MERCHANT' || this.paymentMethod === 'PAID_BY_MERCHANT_COD') {
        return this.totalCharge;
      } else {
        return '0';
      }
    }
    return '-';
  }

  get recipientCharge() {
    if (this.isSubprime) {
      if (this.paymentMethod === 'CASH_ON_DELIVERY') {
        return Number(this.totalCharge) + Number(this.cashOnDeliveryAmount);
      } else if (this.paymentMethod === 'PAID_BY_MERCHANT_COD') {
        return this.cashOnDeliveryAmount;
      } else if (this.paymentMethod === 'PAID_BY_RECEIVER') {
        return this.totalCharge;
      } else {
        return '0';
      }
    }
    return '-';
  }

  get rate() {
    return this.$store.getters['shipment/getSelectedRate'];
  }

  get pickupTime() {
    if (this.$store.getters['shipment/hasSelectedPickupTimeSlot']) {
      const date = this.$store.getters['shipment/getSelectedPickupDate'];
      const minTime = this.$store.getters['shipment/getSelectedPickupMinTime'];
      const maxTime = this.$store.getters['shipment/getSelectedPickupMaxTime'];
      return [date + ' ' + minTime, date + ' ' + maxTime];
    } else {
      return '-';
    }
  }

  get deliveryDate() {
    return this.$store.getters['shipment/getDeliveryDate'] || this.$t('general.notSpecified');
  }
  get pickUpDate() {
    return this.$store.getters['shipment/getPickUpDate'] || this.$t('general.notSpecified');
  }
  get shipmentType() {
    return this.$store.getters['shipment/getShipmentIsSubprime']
      ? this.$t('shipment.confirmAndPayForm.confirmAndPayForm.subprime')
      : this.$t('shipment.confirmAndPayForm.confirmAndPayForm.normal');
  }
  get isDocumentText() {
    return this.$store.getters['shipment/getShipmentIsDocument']
      ? this.$t('shipment.confirmAndPayForm.confirmAndPayForm.isDocumentText')
      : this.$t('shipment.confirmAndPayForm.confirmAndPayForm.notIsDocumentText');
  }

  get orderNotes() {
    return this.$store.getters['shipment/getOrderNotes'];
  }
  get paymentMethod() {
    return this.$store.getters['shipment/getPaymentMethod'];
  }

  get paymentMethodText() {
    return TranslationHelpers.paymentMethodText(this.$store.getters['shipment/getPaymentMethod']);
  }

  get cashOnDeliveryAmount() {
    return this.$store.getters['shipment/getCashOnDeliveryAmount'];
  }

  get fromAddressString() {
    return this.isSubprime
      ? ShipmentHelpers.displayExpressOrderAddress(this.from as ExpressOrderAddress)
      : ShipmentHelpers.displayAddress(this.from as Address);
  }

  get toAddressString() {
    return this.pickUpStoreId
      ? ShipmentHelpers.displayPickUpStore(this.pickUpStore)
      : this.isSubprime
      ? ShipmentHelpers.displayExpressOrderAddress(this.to as ExpressOrderAddress)
      : ShipmentHelpers.displayAddress(this.to as Address);
  }

  get trackingNumber() {
    return this.$store.getters['shipment/getTrackingNumber'];
  }

  private isProceedPrompt() {
    this.ROOT.showPrompt({
      title: this.$t('dialogs.confirmPrompt.title') as string,
      acceptBlt: this.$t('dialogs.confirm') as string,
      closeBlt: this.$t('dialogs.cancel') as string,
      msg: ((this.text.costWarningMsg as string) + '\n' + this.text.confirmationMsg) as string,
      listener: this,
      execution: this.proceed,
    });
  }

  async proceed() {
    const now = new Date();
    //if is same day delivery and validate delivery date and pick up date fail
    if (this.isDocument && !(await ShipmentHelpers.validatePickUpDeliveryDate(this, now))) {
      return;
    }
    // change progress bar to 100 %
    this.$emit('done');

    // cover up with loading page
    this.$store.commit('showLoading');
    let createShipment;
    if (this.isSubprime) {
      createShipment = await this.$store.dispatch('shipment/createExpressShipment');
    } else {
      createShipment = await this.$store.dispatch('shipment/createShipment');
    }
    this.$store.commit('hideLoading');

    // prompt corresponding to createShipment Return
    if (createShipment.success === true) {
      // if success shipment
      if (createShipment.userCompanyAccountBalance < 50.0) {
        this.showUserLowCreditBalancePrompt();
      }
      this.showSuccessAndRedirectPrompt(createShipment);
    } else {
      // if not success
      this.$emit('undone');
      if (createShipment === serverResponseHelpers.USER_COMPANY_INSUFFICIENT_BALANCE) {
        // if insuffucienct balance
        this.showUserInsufficientBalancePrompt();
      } else if (createShipment === serverResponseHelpers.USER_INPUT_ERROR) {
        // if user input error
        this.showUserInputError();
      } else if (createShipment === serverResponseHelpers.TRACKING_NUMBER_ALREADY_IN_USE) {
        this.showTrackingNumberUsedError();
      } else {
        // if do not know what happened
        this.$store.commit('showConnectionError');
      }
    }
  }

  private showSuccessAndRedirectPrompt(createShipment: any = {}) {
    if (this.isDocument) {
      let message = this.$t('dialogs.successAndRedirectPrompt.message') as string;
      // if(typeof createShipment.newBalance !== undefined){
      //   message += ["\n",this.$t("dialogs.successAndRedirectPrompt.currentBalance") as string, createShipment.newBalance].join("");
      // }else{
      //   message = this.$t("dialogs.successAndRedirectPrompt.message") as string;
      // }
      if (createShipment.areaRank || createShipment.contactNumber) {
        message = (('\n' + this.$t('dialogs.successAndRedirectPrompt.writeAreaRankAndContactOnItem')) as string) + '\n';
        if (createShipment.areaRank) {
          message += [
            ('\n' + this.$t('dialogs.successAndRedirectPrompt.areaRank')) as string,
            createShipment.areaRank,
          ].join('');
        }
        if (createShipment.contactNumber) {
          message += [
            ('\n' + this.$t('dialogs.successAndRedirectPrompt.recipientContact')) as string,
            createShipment.contactNumber,
          ].join('');
        }
      }

      this.ROOT.showPrompt({
        title: this.$t('dialogs.successAndRedirectPrompt.title') as string,
        msg: message,
        acceptBlt: this.$t('dialogs.successAndRedirectPrompt.okay') as string,
        listener: this,
        execution: this.successShipment,
      });
    } else {
      this.ROOT.showPrompt({
        title: this.$t('dialogs.successAndRedirectPrompt.title') as string,
        msg: this.$t('dialogs.successAndRedirectPrompt.message') as string,
        acceptBlt: this.$t('dialogs.successAndRedirectPrompt.okay') as string,
        listener: this,
        execution: this.successShipment,
      });
    }
  }
  private showUserInsufficientBalancePrompt() {
    // if not enought balance, prompt user to add credit
    this.ROOT.showPrompt({
      title: this.$t('dialogs.error') as string,
      msg: this.text.userInsufficientBalance as string,
      acceptBlt: this.$t('dialogs.topup') as string,
      listener: this,
      execution: (() => {
        return async () => {
          // TODO: TOPUP
          // this.showTopupPrompt();
          //for now redirect to credit page for top up
          await this.$router.push({ name: 'Credits' });
        };
      })(),
    });
  }

  private showUserLowCreditBalancePrompt() {
    this.ROOT.showPrompt({
      title: this.$t('dialogs.warning') as string,
      msg: this.text.userLowCreditBalance as string,
      acceptBlt: this.$t('dialogs.okay') as string,
      listener: this,
    });
  }
  private async showTopupPrompt() {
    await helper.fetchCardInfo();
    // help user topup
    this.ROOT.showAddCreditsPromptComponentPrompt({
      listener: this,
      execution: (event: string, payload: any) => {
        this.topUp(payload);
      },
    });
  }

  private async topUp(payload: any) {
    // topup
    // cover up top up with loading
    this.$store.commit('showLoading');
    const canPay = await this.$store.dispatch('credits/charge', {
      paymentMethodId: payload.paymentMethodId,
      amount: payload.amount,
    });
    helper.fetchUserLoginInfo(); // refetch newest data
    this.$store.commit('hideLoading');

    // show prompt accordingly of top up
    if (canPay) {
      this.showPaymentSuccessPrompt();
    } else if (canPay === false) {
      this.showPaymentFailPrompt();
    }
  }

  private showPaymentSuccessPrompt() {
    this.ROOT.showPrompt({
      title: this.$t('dialogs.success') as string,
      msg: this.$t('credits.credits.success') as string,
      acceptBlt: this.$t('dialogs.okay') as string,
    });
  }
  private showPaymentFailPrompt() {
    this.ROOT.showPrompt({
      title: this.$t('dialogs.fail') as string,
      msg: this.$t('credits.credits.fail') as string,
      acceptBlt: this.$t('dialogs.close') as string,
    });
  }

  private showUserInputError() {
    this.ROOT.showPrompt({
      title: this.$t('dialogs.error') as string,
      msg: this.text.userInputError as string,
      acceptBlt: this.$t('dialogs.close') as string,
    });
  }
  private showTrackingNumberUsedError() {
    this.ROOT.showPrompt({
      title: this.$t('dialogs.error') as string,
      msg: this.$t('shipment.shipmentForm.qrCodeScanner.error.alreadyInUse') as string,
      acceptBlt: this.$t('dialogs.close') as string,
    });
  }

  private async successShipment() {
    this.$store.commit('showLoading');
    await helper.fetchUserLoginInfo();
    this.reset = true; // reset pikcup, rates, shipment info if reset === true
    await this.$router.push({ name: 'TrackingList' });
  }

  private showCostWaringPrompt() {
    this.ROOT.showPrompt({
      title: this.$t('dialogs.warning') as string,
      msg: this.$t('shipment.confirmAndPayForm.confirmAndPayForm.costWarningPrompt.message') as string,
      acceptBlt: this.$t('dialogs.close') as string,
    });
  }
  private toLastPage() {
    this.$router.back();
  }

  destroyed() {
    // reset all on destory
    if (this.reset) {
      this.resetAll();
    }
  }

  resetAll() {
    this.$store.commit('shipment/resetShipment');
    this.$store.commit('shipment/resetRate');
  }
}
